import React, {useEffect, useRef, useState} from "react"
// import { LazyLoadImage } from 'react-lazy-load-image-component';
// import { ReactComponent as Empowering } from "../images/empowering-gray-100.svg";
// import { ReactComponent as TimeSmart } from "../images/time-smart-gray-100.svg";
// import { ReactComponent as MoneySaving } from "../images/money-saving-gray-100.svg";
// import { ReactComponent as EcoFriendly } from "../images/eco-friendly-gray-100.svg";
// import { ReactComponent as ValueBoosting } from "../images/value-boosting-gray-100.svg";
import { ReactComponent as Protection } from "../images/protection-gray-100.svg";
import { ReactComponent as Respect } from "../images/respect-gray-100.svg";
import { ReactComponent as Impartiality } from "../images/impartiality-gray-100.svg";
import { ReactComponent as LivletIcon } from "../images/livlet-icon.svg";
import { ReactComponent as BookTradespeople } from "../images/CarouselBookTradespeople.svg";
import { ReactComponent as PlanAheadCarousel } from "../images/CarouselPlanAhead.svg";
import { ReactComponent as ReceiveMaintenance } from "../images/CarouselReceiveMaintenance.svg";
import { ReactComponent as ShareReminders } from "../images/CarouselShareReminders.svg";
import { ReactComponent as StoreInformation } from "../images/CarouselStoreInformation.svg";
import { ReactComponent as CarouselArrow } from "../images/CarouselArrow.svg";
import { ReactComponent as HeroGraphicPlayButton } from "../images/HeroGraphicPlayButton.svg";
import { ReactComponent as PlayButtonIcon } from "../images/PlayButtonIcon.svg";
import heroGraphicCloud from "../images/HeroGraphicCloud.png"
import HelmetMetaData from "../components/HelmetMetaData";
import {Link, useHistory, useLocation} from "react-router-dom";
import envConfig from "../config";
import {useCookies} from "react-cookie";
import moment from "moment";

import styled from "styled-components";

import Slider from "react-slick";
import HomeInformation from "../components/HomePageBlocks/HomeInformation";
// import PartnerServices from "../components/HomePageBlocks/PartnerServices";
// import Finances from "../components/HomePageBlocks/Finances";
// import ScheduleCalendar from "../components/HomePageBlocks/ScheduleCalendar";
// import Guidance from "../components/HomePageBlocks/Guidance";
import SubscriptionPopup from "../components/SubscriptionPopup";
import { Modal } from "antd";

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Home = () => {
  let history = useHistory();
  const url = useLocation().pathname;
  const recordsRef = useRef(null);
  const carouselRef = useRef<any>(null);

  const [cookies, setCookie, removeCookie] = useCookies(['livlet-referral']);

  let queryParams = useQuery();
  const referralParam = queryParams.get("referral");

  const [scrollPoint, setScrollPoint] = useState(0)
  const [isModalTime, toggleModalTime] = useState(false)
  const [innerWidth, setInnerWidth] = useState(window.innerWidth)
  const [isModalOpen, toggleModal] = useState(false)

  useEffect(() => {
    function handleResize() {
      setInnerWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => { window.removeEventListener('resize', handleResize) }
  })

  useEffect(() => {
    function handleScroll() {
      setScrollPoint(window.scrollY)
    }
    window.addEventListener('scroll', handleScroll)
    return () => { window.addEventListener('scroll', handleScroll) }
  })

  useEffect(() => {
    setTimeout(() => toggleModalTime(true), 15000)
  }, [])

  const showPopupWindow = innerWidth > 500 ? isModalTime : (scrollPoint > 0 && scrollPoint + 1000 >= document.body.scrollHeight)

  const signUpFreeSection = () => <div className={"stripe"}>
    <div className={"container container-column container-trial"}>
      <span>Try it for yourself, for free.</span>
      <a className={"home-signup"} href={`${envConfig.WEBAPP_URL}/register`} target={"_blank"} rel={"noreferrer"}>
        <button className={"button-green container-button"}>
          Sign-up free
        </button>
      </a>
    </div>
  </div>

  useEffect(() => {
    if (referralParam) {
      setCookie('livlet-referral', referralParam, { path: '/', expires: moment().add(1, 'day').toDate(), domain: '.livlet.com' });
    }
  }, [referralParam])

  const isCampaignPage = url.includes("save-money-home") || url.includes("organize-your-household") ||
    url.includes("advice-guidance") || url.includes("avoid-home-emergencies")

  const [activeSlide, setActiveSlide] = useState(0);

  return (
    <div style={{marginTop: "4.5rem"}}>
      <HelmetMetaData
        title={"Livlet | Home intelligence | Simplifying homeownership"}
        description={"A digital household management app"}
        image={"https://www.livlet.com/img/livletLogo.jpg"}
        keywords={"homeowner application, home management, UK households, " +
        "households eco efficiency, homeowner savings, home repairs, home improvement"} />
      {showPopupWindow && <SubscriptionPopup />}

      <ModalStyled
        visible={isModalOpen}
        onOk={() => toggleModal(false)}
        onCancel={() => toggleModal(false)}
        footer={null}
        destroyOnClose={true}
        width={800}
      >
        <div className={"video-container"}>
          <iframe className="video" src="https://www.youtube.com/embed/fs33oZLXNlw" allowFullScreen />
        </div>
      </ModalStyled>

      <div className={"stripe-white"}>
        <div className={"container"} style={{flexDirection: "column",     alignItems: "flex-start"}}>
        <div className={"announcement-container-title"}>
          ANNOUNCEMENT
        </div>
        <div className={"announcement-container-content"}>
          <div className={"announcement-container-headline"}>We regret to inform our users that after much consideration we have made the difficult decision to close Livlet down.</div>
          <p>Whilst at Livlet we believe that the product is very much needed and is beneficial for home owners, we will no longer be able to continue offering our service to customers.</p>
          <p>Our platform will remain in place for existing users of the Livlet platform until the 10th of March, allowing users to download their data. After this date all user data will be deleted.</p>
          <p>Following this, the product will remain available in an unsupported state. If you would like to maintain your account after this date, please contact <a href={"mailto:support.uk@livlet.com"}>support.uk@livlet.com</a>.</p>
          <p>Thank you for using Livlet. We are grateful for you having joined us on our journey to help homeowners.</p>
          <p>Wishing you well,</p>

          <div className={"footer-text"}>The Livlet Team</div>
        </div>
        </div>
      </div>

      {/*hero header*/}
      {!isCampaignPage && <HeroBanner>
        <HeroCloud>
            <img src={heroGraphicCloud} alt={""} />
          <HeroGraphicPlayButton onClick={() => toggleModal(true)} />
        </HeroCloud>
        <span className={"outer-wrapper"}>
          <span className={"inner-wrapper"}>
            <span className={"hero-text"}>
              Your home information portal
            </span>
            <span className={"hero-title"}>
              The intelligent owner's home management app
            </span>
            <span className={"button-wrapper"}>
              <span className={"hero-button darkened"} onClick={() => toggleModal(true)}>
                  Play our video <PlayButtonIcon />
              </span>
              <Link className={"hero-button outlined"} to="/features">
                  View our features &gt;
              </Link>
            </span>
          </span>
        </span>
      </HeroBanner>}

      <CarouselWrapper>
        <CarouselDescription>
          <span className={"carousel-header"}>How it works</span>
          {/*<span className={"carousel-title"}>{carouselText[activeSlide]}</span>*/}
          {/*{innerWidth > 1024 && dots()}*/}
          <span className={"carousel-title"}>Our features to help you manage your home</span>
          <span className={"carousel-button"} onClick={() => history.push("/features")}>View features &gt;</span>
        </CarouselDescription>
        <SliderWrapper>
          <Slider
            className={"slider variable-width"}
            ref={slider => (carouselRef.current = slider)}
            infinite={true}
            arrows={true}
            prevArrow={<></>}
            responsive={[
              //{breakpoint: 560, settings: {slidesToShow: 1}},
              {breakpoint: 650, settings: {slidesToShow: 2}},
              {breakpoint: 1024, settings: {slidesToShow: 3}},
              {breakpoint: 1370, settings: {slidesToShow: 2}},
              {breakpoint: 5000, settings: {slidesToShow: 3}},
            ]}
            nextArrow={<CarouselArrow />}
          >
            <CarouselSlide className={activeSlide === 0 ? "active-slide" : ""} onClick={() => setActiveSlide(0)}>
              <span className={"slide-title"}><StoreInformation />Home information</span>
              <span>Store all your home information in one place</span>
            </CarouselSlide>
            <CarouselSlide className={activeSlide === 1 ? "active-slide" : ""} onClick={() => setActiveSlide(1)}>
              <span className={"slide-title"}><BookTradespeople />Help for your home</span>
              <span>Book tradespeople for repairs and emergencies, receive members benefits</span>
            </CarouselSlide>
            <CarouselSlide className={activeSlide === 2 ? "active-slide" : ""} onClick={() => setActiveSlide(2)}>
              <span className={"slide-title"}><ReceiveMaintenance />Reminders for your home</span>
              <span>Receive maintenance, warranty, certificate reminders</span>
            </CarouselSlide>
            <CarouselSlide className={activeSlide === 3 ? "active-slide" : ""} onClick={() => setActiveSlide(3)}>
              <span className={"slide-title"}><PlanAheadCarousel />Manage your costs</span>
              <span>Plan ahead and know your costs</span>
            </CarouselSlide>
            <CarouselSlide className={activeSlide === 4 ? "active-slide" : ""} onClick={() => setActiveSlide(4)}>
              <span className={"slide-title"}><ShareReminders />Manage your home together</span>
              <span>Share reminders with family and other members of your household</span>
            </CarouselSlide>
          </Slider>
        </SliderWrapper>
      </CarouselWrapper>

      {/*hi, I'm livlet*/}
      {(url === "/avoid-home-emergencies") && <div className={"stripe-gray"}>
        <div className={"container container-column"} style={{alignItems: "center", maxWidth: "50rem"}}>
          <LivletIcon/>
          <h2 className={"greeting-header"}>Hi, I'm <span className={"highlight-green"}>Livlet</span>!</h2>
          {url === "/avoid-home-emergencies" ? <>
            <p className={"greeting-text"}>I can teach you how to shut off water if you have a leak.</p>
            <p className={"greeting-text"}>Or what to do in the event of a flood.</p>
            <p className={"greeting-text"}>Or how to restart your boiler when you lose hot water.</p>
            <p className={"greeting-text"}>Weather the storms with Livlet.</p>
          </> : <>
            <p className={"greeting-text"}>Imagine if every piece of information about your home was safely
              stored and accessible in just a few clicks.</p>
            <p className={"greeting-text"}>Imagine a trusted source of advice helping you care for your home while
              saving time and money, and improving its value and carbon footprint.</p>
          </>}
        </div>
      </div>}

      {/*
        order:
        1. home information
        2. partner services
        3. finances
        4. schedule and calendar
        5. guidance
      */}
      <HomeInformation isDesktop={innerWidth > 768} anchor={recordsRef} />
      {/*<PartnerServices isDesktop={innerWidth > 768} />*/}
      {/*<Finances isDesktop={innerWidth > 768} />*/}
      {/*<ScheduleCalendar isDesktop={innerWidth > 768} />*/}
      {/*<Guidance isDesktop={innerWidth > 768} />*/}

      {/*<div className={"stripe-white"}>*/}
      {/*  <div className={"container container-column container-cta"}>*/}
      {/*      <button className={"button-cta-big button-dark-green"}*/}
      {/*          onClick={() => history.push("/features")}>See more features</button>*/}
      {/*  </div>*/}
      {/*</div>*/}

      {/*man and son*/}
      {/*<div className={"square-wrapper"}>*/}
      {/*    <div className={"square square-green square-start square-left"}>*/}
      {/*        <div className={"square-content home-square-text"}>*/}
      {/*            Connecting people, their homes and their communities so that everyone can lead a richer, more sustainable*/}
      {/*            life, together.*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*    <div className={"square square-green display-desktop square-right"} style={{*/}
      {/*      backgroundImage: `url("https://www.livlet.com/img/connecting-people.webp")`,*/}
      {/*      backgroundPosition: "center",*/}
      {/*      backgroundRepeat: "no-repeat",*/}
      {/*      backgroundSize: "cover"*/}
      {/*    }}*/}
      {/*         role="img" aria-label="Father and young son sitting together on a sofa using*/}
      {/*    a digital table in a living room with large windows and sun light"/>*/}
      {/*    <div className={"image-wrapper display-mobile"}>*/}
      {/*        <LazyLoadImage*/}
      {/*            alt={"Father and young son sitting together on a sofa using a digital table in a living room with large windows and sun light"}*/}
      {/*            src={"https://www.livlet.com/img/connecting-people.webp"}/>*/}
      {/*    </div>*/}
      {/*</div>*/}

      {/*try for yourself*/}
      {/*{innerWidth > 1024 && signUpFreeSection()}*/}

      {/*invaluable companion*/}
      {/*{url === "/" && <div className={"stripe-white"}>*/}
      {/*  <div className={"container feature-container-title"}>*/}
      {/*    <h3>Your invaluable companion</h3>*/}
      {/*  </div>*/}
      {/*  <div className={"container feature-container"}>*/}
      {/*    <div className={"feature-column"}>*/}
      {/*      <Empowering/>*/}
      {/*        <span className={"feature-column-content"}>*/}
      {/*          <h4>Empowering</h4>*/}
      {/*          <p>With data at your fingertips, you can make informed decisions and command a smooth-running home.</p>*/}
      {/*        </span>*/}
      {/*    </div>*/}
      {/*    <div className={"feature-column"}>*/}
      {/*      <TimeSmart/>*/}
      {/*        <span className={"feature-column-content"}>*/}
      {/*          <h4>Time-smart</h4>*/}
      {/*          <p>Get access to the information you need in seconds. No more wasted hours spent hunting high and low.</p>*/}
      {/*        </span>*/}
      {/*    </div>*/}
      {/*    <div className={"feature-column"}>*/}
      {/*      <MoneySaving/>*/}
      {/*        <span className={"feature-column-content"}>*/}
      {/*          <h4>Money-saving</h4>*/}
      {/*          <p>Reduce the need for repairs and cut your bills. Stay on top of your maintenance with our smart analysis,*/}
      {/*            personalised tips and proactive alerts.</p>*/}
      {/*        </span>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div className={"container feature-container"}>*/}
      {/*    <div className={"feature-column"}>*/}
      {/*      <EcoFriendly/>*/}
      {/*        <span className={"feature-column-content"}>*/}
      {/*          <h4>Eco-friendly</h4>*/}
      {/*          <p>Do right by the planet and your conscience by following our personalized tips on how to trim your home's*/}
      {/*            carbon footprint.</p>*/}
      {/*        </span>*/}
      {/*    </div>*/}
      {/*    <div className={"feature-column"}>*/}
      {/*      <ValueBoosting/>*/}
      {/*        <span className={"feature-column-content"}>*/}
      {/*          <h4>Value-boosting</h4>*/}
      {/*          <p>Preserve and increase the value of your home by making well informed decisions.</p>*/}
      {/*        </span>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>}*/}

      {/*woman on couch*/}
      {/*<div className={"square-wrapper"}>*/}
      {/*  <div className={"square square-green square-end square-left"}>*/}
      {/*    <div className={"square-content home-square-text"}>*/}
      {/*      We're on a mission to simplify home ownership by providing the tools to help homeowners manage their homes effortlessly.*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div className={"square square-green display-desktop square-right"} style={{*/}
      {/*    backgroundImage: `url("https://www.livlet.com/img/simplify-ownership.webp")`,*/}
      {/*    backgroundPosition: "center",*/}
      {/*    backgroundRepeat: "no-repeat",*/}
      {/*    backgroundSize: "cover"*/}
      {/*  }} role="img" aria-label="Young woman sitting cross legged on the sofa in a cozy bright living room using a laptop" />*/}
      {/*  <div className={"image-wrapper display-mobile"}>*/}
      {/*    <LazyLoadImage*/}
      {/*      alt={"Young woman sitting cross legged on the sofa in a cozy bright living room using a laptop"}*/}
      {/*      src={"https://www.livlet.com/img/simplify-ownership.webp"} />*/}
      {/*  </div>*/}
      {/*</div>*/}

      {/*a trustworthy service*/}
      <div className={"stripe-white"}>
        <div className={"container feature-container-title"}>
          <h3>A trustworthy service that's on your side</h3>
        </div>
        <div className={"container feature-container"}>
          <div className={"feature-column"}>
            <Protection />
            <span className={"feature-column-content"}>
              <h4>Protection</h4>
              <p>Your data is stored safely, and only you have the key.</p>
            </span>
          </div>
          <div className={"feature-column"}>
            <Respect />
            <span className={"feature-column-content"}>
              <h4>Respect</h4>
              <p>We respect your time. Your inbox is only for must-see notifications.</p>
            </span>
          </div>
          <div className={"feature-column"}>
            <Impartiality />
            <span className={"feature-column-content"}>
              <h4>Impartiality</h4>
              <p>We are unbiased in the guidance and alerts we provide.</p>
            </span>
          </div>
        </div>

        {/*{innerWidth <= 1024 && signUpFreeSection()}*/}
      </div>
    </div>
  )
}

const HeroBanner = styled.div`
  height: 28.5rem;
  max-width: 1440px;
  margin: auto;
  background: #EFFAFE;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  column-gap: 3rem;
  
  .outer-wrapper {
    width: 36.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .inner-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
  }
  
  .hero-text {
    width: 100%;
    text-align: left;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: #00CC33;
    text-transform: uppercase;
  }
  
  .hero-title {
    width: 100%;
    text-align: left;
    color: #3E5D58;
    font-weight: 700;
    font-size: 3.375rem;
    line-height: 3.5rem;
  }
  
  .button-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .hero-button {
    cursor: pointer;
    height: 3rem;
    min-width: 9.75rem;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #3E5D58;
    transition: all .5s ease;
    background: #EFFAFE;
    padding: 0.75rem 1.5rem;
  }
  
  .outlined { border: 1px solid #3E5D58; }
  .darkened { display: none; }
  
  .hero-button:hover { 
    opacity: 0.5;
    transition: all .5s ease;
  }
  
  @media (max-width: 1485px) {
    .outer-wrapper {
      justify-content: flex-end;
      padding-right: 1rem;
    }
  }
  
  @media (max-width: 1380px) {
    background-position: -5rem 0; 
  }
  
  @media (max-width: 1240px) {
    background-position: -5rem 0; 
    
    .outer-wrapper {
      width: 30%;
    }
    
    .hero-title {
      font-size: 2.375rem;
      line-height: 2.5rem;
    }
  
    .button-wrapper {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  
  @media (max-width: 1150px) {
      .outer-wrapper {
        width: 25%;
      }
      
      .hero-text {
        font-size: 1rem;
        line-height: 1.375rem;
      }
    
      .hero-title {
        font-size: 2rem;
        line-height: 2.25rem;
      }
      
      background-position: -7rem 0; 
    }
  
  @media (max-width: 1024px) {
    flex-direction: column;
    background-image: none;
    height: auto;
    border-radius: 0;
    padding-top: 1.5rem;
   
    .outer-wrapper {
      width: 100%;
      padding: 2rem 1.5rem;
      justify-content: center;
      align-items: center;
    }
    
    .inner-wrapper { align-items: center; }
    .darkened { 
      display: flex;
      background: #3E5D58;
      color: white;
      gap: 0.5rem;
     }
    
    .hero-button { 
      width: 100%;
    }
    
    .hero-text {
      text-align: center;
      font-size: 1rem;
      line-height: 1.5rem;
    }
  
    .hero-title {
      text-align: center;
      font-size: 2.5rem;
      line-height: 2.75rem;
    }
  }
`

const CarouselWrapper = styled.div`
  max-width: 1440px;
  margin: auto;
  padding: 4rem 0;
  display: flex;
  justify-content: space-between; //flex-end;
  border: 1px solid white;
  align-items: center;
  gap: 1.5rem;
  
  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 3.375rem 0;
  }
`

const CarouselDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 22.5rem;
  padding-left: 1rem;
  
  .carousel-header {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: #3E5D58;
  }
  
  .carousel-title {
    font-weight: 700;
    font-size: 1.625rem;
    line-height:  1.75rem;
    color: #3E5D58;
  }
  
  .carousel-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #009966;
    height: 3rem;
  }
  
  @media (max-width: 1024px) {
    width: 100%;
    padding: 0 1.25rem;
  }
`

const CarouselSlide = styled.div`
  display: flex!important;
  flex-direction: column!important;
  padding: 1.5rem;
  gap: 1.5rem;
  width: 20rem!important;
  height: 15rem!important;
  margin-right: 1.5rem;
  background: #FFFFFF;
  border-radius: 8px;
  
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  color: #666F7A;
  margin: 1rem;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  
  // &.active-slide {
  //   box-shadow: 0px 4px 20px rgba(42, 128, 225, 0.25);
  //   font-weight: 600;
  //   color: #45655E;
  //   opacity: 1;
  // }
  
  .slide-title {
    display: flex; 
    justify-content: space-between;
    font-weight: 700;
    font-size: 1.625rem;
    line-height: 1.75rem;
    color: #3E5D58;
    width: 100%;
    gap: 1.5rem;
    align-items: center;
  }
  
  .slide-title svg { flex-shrink: 0; }
 
  @media (max-width: 1024px) {
    padding: 1rem;
    font-size: 0.75rem;
    line-height: 1.125rem;
    width: 14rem!important;
    height: 15.25rem!important;
    gap: 0.5rem;
    
    .slide-title {
      display: flex; 
      flex-direction: column;
      gap: 0.75rem;
      font-size: 1rem;
      line-height: 1.25rem;
      align-items: flex-start;
    }
  }
  
  @media (max-width: 768px) {
    width: 11rem!important;
    height: 17.25rem!important;
  }
  
  @media (max-width: 450px) {
    width: 10rem!important;
    height: 17.25rem!important;
  }
`

const SliderWrapper = styled.div`
  max-width: 75vw;
  
  .slick-next { 
    width: 6.25rem; 
    height: 100%;
  }
  
  @media (min-width: 1600px) {
    max-width: 1080px;
  }
  
  @media (max-width: 1500px) {
    .slick-next { right: 0; }
  }
  
  @media (max-width: 1370px) {
    max-width: 700px;
  }

  @media (max-width: 1024px) {
    padding-left: 1.5rem;
    max-width: 100%;
    margin: 1.5rem 0;
  }
  
  @media (max-width: 500px) {
    padding-left: 0;
  }
`

const HeroCloud = styled.div`
  background-image: url(${heroGraphicCloud});
  width: 614px;
  height: 338px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  img { display: none; }
  
  svg { 
    margin-left: 12px; 
    margin-top: 12px; 
    cursor: pointer;
    transition: .5s all ease;
  }
  
  svg:hover { 
    transform: scale(1.1);
    transform-origin: center;
  }
  
  @media (max-width: 1024px) {
    background-image: none;
    width: auto;
    height: auto;
    svg { display: none!important; }
    img { 
      display: block;
      max-width: 100%;
    }
  }
`

const ModalStyled = styled(Modal)`
  .ant-modal-body { 
    background-color: rgba(0, 0, 0, 0);
    padding: 0;
    height: 10px;
  }
  
  .video-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
  }
  
  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`

export default Home